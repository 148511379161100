small {
	font-size: 13px;
  }
  .ant-tabs-nav-container {
	font-size: 15px;
  }
  .ant-layout-header {
	padding: 0 20px;
  }
  .nowrap {
	white-space: nowrap;
  }
  .has-tooltip {
	border-bottom: 1px dotted;
  }
  .ant-dropdown {
	z-index: 2000;
  }
  .ant-tooltip-inner {
	width: max-content;
  }
  .ant-tabs-bar,
  .ant-pagination {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  .ant-btn {
	line-height: 1.15;
  }
  .ant-btn > i.fa {
	margin-right: 0.3em;
  }
  .ant-btn-circle > i.fa:before {
	position: relative;
	left: 0.175em;
  }
  .ant-btn-circle > i.fa-comment-o:before {
	top: -0.05em;
  }
  .ant-btn-circle > i.anticon-edit:before {
	position: relative;
	left: -0.03em;
  }
  .ant-btn-circle > i.anticon-check:before {
	position: relative;
	left: 0.04em;
  }
  .ant-card.menucard {
	color: #fff;
	background-color: #288ecd;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	cursor: pointer;
  }
  .ant-card.menucard:hover {
	background-color: #4ea8d9;
	border-color: #4ea8d9;
  }
  .ant-card.menucard .fa-comment-o:before {
	position: relative;
	top: -0.1em;
  }
  .ant-card.contact-card {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
  }
  .ant-card.contact-card .ant-card-head-title {
	text-align: center;
  }
  .ant-card.contact-card > .ant-card-body {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
  }
  .ant-card.contact-card > .ant-card-body > .flex-full-height {
	flex: 1 0 auto;
  }
  .ant-card.contact-card > .ant-card-body > .ant-btn {
	width: 100%;
	padding: 0;
	padding-left: 8px;
	padding-right: 8px;
  }
  .contact-card-col {
	display: flex;
	flex: 1 0 auto;
  }
  .ant-table {
	border-radius: 0 0 0 0;
  }
  .ant-table table {
	border-radius: 0 0 0 0;
  }
  .ant-table-thead > tr > th {
	word-break: normal;
  }
  .ant-table-thead > tr > th.right-aligned-col {
	text-align: center;
  }
  .ant-table-thead > tr > th.centered-col {
	text-align: center;
  }
  .ant-table-tbody > tr > td {
	word-break: normal;
  }
  .ant-table-tbody > tr > td.right-aligned-col {
	text-align: right;
  }
  .ant-table-tbody > tr > td.centered-col {
	text-align: center;
  }
  .delivery-status-col {
	width: 2.5em;
	text-align: center;
  }
  .delivery-status-col .ant-row {
	display: flex;
	flex: 1 0 auto;
  }
  .delivery-status-col .ant-row .ant-col-12 {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	text-align: center;
	justify-content: center;
  }
  .status-col {
	width: 2em;
	text-align: center;
  }
  .action-col {
	width: 2em;
	white-space: nowrap;
  }
  .del-unconfirmed {
	color: #f04134;
  }
  .del-requested,
  .del-confirmed {
	color: rgba(0, 0, 0, 0.5);
	font-style: italic;
  }
  .profile-menu {
	margin-top: 5px;
	margin-left: 15px;
  }
  .profile-menu-text.ant-dropdown-menu-item:hover {
	cursor: default;
	background-color: #fff;
  }
  .profile-menu-text.ant-dropdown-menu-item-selected,
  .profile-menu-text.ant-dropdown-menu-item-selected > a {
	cursor: default;
	color: inherit;
	background-color: inherit;
  }
  .ant-calendar-last-month-cell .ant-calendar-date,
  .ant-calendar-next-month-btn-day .ant-calendar-date {
	color: rgba(0, 0, 0, 0.5);
  }
  .ant-calendar-disabled-cell .ant-calendar-date {
	color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-card.invoice {
	background-color: #f4f4f4;
	color: #666;
	cursor: pointer;
  }
  .ant-card.invoice .ant-card-body {
	text-align: center;
  }
  .ant-card.invoice:hover,
  .ant-card.invoice:active {
	color: #4ea8d9;
  }
  .ant-card.invoice .swap {
	transition: opacity 0.3s ease;
  }
  .ant-card.invoice .swap:not(.mouseover) {
	opacity: 1;
  }
  .ant-card.invoice .swap.mouseover {
	opacity: 0;
  }
  .ant-card.invoice:hover .swap:not(.mouseover) {
	opacity: 0;
  }
  .ant-card.invoice:hover .swap.mouseover {
	opacity: 1;
  }
  .ant-card.invoice .overlay {
	position: relative;
  }
  .ant-card.invoice .overlay .swap:not(.mouseover) {
	position: absolute;
	z-index: 10;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
  }
  .ant-card.invoice .overlay .icon-stack {
	position: relative;
  }
  .ant-card.invoice .overlay .icon-stack i + i {
	position: absolute;
	z-index: 20;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
  }
  .search-clear {
	z-index: 1;
	background: #fff;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	transition: color 0.3s, opacity 0.3s;
  }
  .search-clear:hover {
	color: rgba(0, 0, 0, 0.43);
  }
  .permissions-table .ant-table-tbody > tr > td {
	padding: 6px 8px;
  }
  .permissions-table .ant-table-title,
  .permissions-table .ant-table-footer,
  .permissions-table .ant-table-thead > tr > th:not(.ant-table-selection-column) {
	padding: 10px 8px;
  }
  .ant-badge i.fa.fa-4x {
	font-size: 86px;
  }
  .sp-notification-list .ant-modal-footer {
	border-top: 0;
	padding: 0 0;
  }
  .sp-notification-list .ant-modal-content {
	padding: 10px 20px;
  }
  .sp-notification-item {
	border-bottom: 1px solid #ccc;
	vertical-align: middle;
  }
  .sp-notification-item:last-child {
	border-bottom: none;
  }
  .sp-notification-list table {
	width: 100%;
  }
  .sp-notification-list table td.sp-notification-body {
	padding-right: 16px;
	padding-left: 6px;
	width: 82%;
  }
  .sp-notification-list table td {
	padding-top: 8px;
	padding-bottom: 8px;
  }

  #sp-main-menu .ant-menu-item{
	  margin-left: 0;
	  margin-right: 0;
	  padding-left: 20px;
	  padding-right: 20px;
	  line-height: 50px;
  }

  @media only screen and (min-width: 785px) and (max-width: 900px) {
    #sp-main-menu .ant-menu-item {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  @media only screen and (max-width: 784px) {
    #sp-main-menu .ant-menu-item {
      padding-left: 9px;
      padding-right: 9px;
    }
  }
