/* -------------------- Main menu colors ---------------------- */

.mainMenu.ant-menu-horizontal > .ant-menu-item a{
    color: rgba(0, 0, 0, 0.75);
}
.mainMenu.ant-menu-horizontal > .ant-menu-item-active.ant-menu-item-selected a,
.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active a,
.mainMenu.ant-menu-horizontal > .ant-menu-item-selected a,
.mainMenu.ant-menu-horizontal > .ant-menu-item-active.ant-menu-item-selected a .ant-badge,
.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active a .ant-badge,
.mainMenu.ant-menu-horizontal > .ant-menu-item-selected a .ant-badge
{
    color: #288ecd;
}

.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active.ant-menu-item-selected::after,
.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active::after,
.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active:hover:after,
.mainMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected::after{
    border-bottom: 2px solid #288ecd;

}

/* -------------------- Footer menu colors ---------------------- */
.footerMenu a {
    color: #288ecd;
}